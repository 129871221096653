
































































































































































































































































































































































































































































































































































































































































































































































































































































































































.imgbox {
  .el-form-item__content {
    width: 256px !important;
    height: 156px;
  }
}
.form-inline {
  width: 45%;
  p {
    width: 20rem;
  }
}
.classReviewDetail {
  .lessNumcount {
    .el-icon-arrow-down:before {
      content: "\e6df" !important;
    }
  }
}
.el-image {
  display: block;
}

.imgBoxs {
  display: flex;
  > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
.editLecture {
  .el-form-item__content {
    display: flex;
    align-items: center;
  }
}
